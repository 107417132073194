import { GetUserProfile } from "../../types/types";
import { dayFormatter } from "../../lib/utils";
import { useGetPlans } from "../../query/useSubscription";
import CustomDropDownForPlans from "./CustomDropDownForPlans";
import { useEffect, useState } from "react";
import { Switch } from "../ui/switch";
import { format } from "date-fns";
import { CalendarDays, Pencil, SquarePlus, Trash, Trash2 } from "lucide-react";
import CalendarDialog from "./CalendarDialog";
import ButtonLoading from "../common/ButtonLoading";
import {
  useAddUserPlan,
  useDeleteUserPlan,
  usePutUserPlanUpdate,
} from "../../query/useUser";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import PasswordModal from "./PasswordModal";
import ConfirmDialog from "../common/ConfirmDialog";

const Subscription = ({
  profile,
  email,
}: {
  profile: GetUserProfile;
  email: string;
}) => {
  const queryClient = useQueryClient();
  const { data: plans } = useGetPlans();
  const [date, setDate] = useState<Date | undefined>();
  const [selectedPlan, setSelectedPlan] = useState<string>("Select");
  const [isCheckAutoRenew, setIsCheckAutoRenew] = useState<boolean>(false);
  const [isFreeAccess, setIsFreeAccess] = useState<boolean>(false);
  const [isOpenCalendarDialog, setIsOpenCalendarDialog] =
    useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isOpenAddPassword, setIsOpenAddPassword] = useState<boolean>(false);
  const [isOpenUpdatePassword, setIsOpenUpdatePassword] =
    useState<boolean>(false);
  const [isOpenTogglePassword, setIsOpenTogglePassword] =
    useState<boolean>(false);
  const [isOpenDeletePassword, setIsOpenDeletePassword] =
    useState<boolean>(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] =
    useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>("");

  const { mutate: addPlan, isPending: addPlanLoading } = useAddUserPlan();
  const { mutate: updatePlan, isPending: updatePlanLoading } =
    usePutUserPlanUpdate();
  const { mutate: deletePlan, isPending: deletePlanLoading } =
    useDeleteUserPlan();

  const handleAddPlan = () => {
    setPasswordError("");
    addPlan(
      {
        email: email,
        plan: plans?.find((item) => item?.uuid === selectedPlan)?.id,
        auto_renew: true,
        password: password,
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Plan has been updated");
          }
          setIsEditable(false);
          setIsOpenAddPassword(false);
          setPassword("");
          queryClient.invalidateQueries({ queryKey: ["getUserList"] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  const handlePlanUpdate = () => {
    setPasswordError("");
    updatePlan(
      {
        email: email,
        plan: plans?.find((item) => item?.uuid === selectedPlan)?.id,
        auto_renew: isCheckAutoRenew,
        password: password,
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Plan has been updated");
          }
          setIsEditable(false);
          setIsOpenUpdatePassword(false);
          setPassword("");
          queryClient.invalidateQueries({ queryKey: ["getUserList"] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  const handlePlanAutoRenewal = () => {
    setPasswordError("");
    updatePlan(
      {
        email: email,
        plan: plans?.find((item) => item?.uuid === selectedPlan)?.id,
        auto_renew: !isCheckAutoRenew,
        password: password,
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Plan has been updated");
          }
          setIsEditable(false);
          setIsOpenTogglePassword(false);
          setPassword("");
          queryClient.invalidateQueries({ queryKey: ["getUserList"] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  const handleDeletePlan = () => {
    setPasswordError("");
    deletePlan(
      {
        email: email,
        password: password,
      },
      {
        onSuccess: (res) => {
          if (res?.message) {
            toast.success(res?.message);
          } else {
            toast.success("Plan has been deleted");
          }
          setIsOpenDeleteConfirm(false);
          setIsOpenDeletePassword(false);
          setSelectedPlan("Select");
          queryClient.invalidateQueries({ queryKey: ["getUserList"] });
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            if (err.response.data.status === "field error") {
              if (err.response.data.message) {
                setPasswordError(err.response.data.message?.password?.[0]);
              }
            } else {
              if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                toast.error("Something went wrong");
              }
            }
          }
        },
      }
    );
  };

  useEffect(() => {
    if (profile?.current_plan?.uuid) {
      setSelectedPlan(profile?.current_plan?.uuid);
    }
    setIsCheckAutoRenew(profile?.subscription_auto_renew);
  }, [profile?.current_plan?.uuid, profile?.subscription_auto_renew]);

  return (
    <>
      <div className="flex lg:flex-row flex-col-reverse py-5 lg:px-10 px-5 flex-wrap justify-between items-center gap-y-5">
        <div className="flex lg:items-center gap-x-5 flex-wrap gap-y-2">
          <p className="font-medium text-lg w-[150px]">Current Plan:</p>
          <CustomDropDownForPlans
            values={plans}
            value={
              selectedPlan !== "Select"
                ? plans?.find((item) => item?.uuid === selectedPlan)
                    ?.short_text +
                  " " +
                  plans?.find((item) => item?.uuid === selectedPlan)?.currency
                : selectedPlan
            }
            setValue={setSelectedPlan}
            disabled={!isEditable}
          />
        </div>
        {profile?.has_active_subscription ? (
          <>
            {isEditable ? (
              <div className="flex items-center gap-x-3">
                <button
                  className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
                  onClick={() => setIsOpenUpdatePassword(true)}
                >
                  Confirm
                </button>
                <button
                  className="px-7 py-2.5 rounded-[12px] bg-lightpurple text-[#7D7A9A]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-x-2 self-end">
                <div
                  className="cursor-pointer hover:bg-gray-100 rounded-full w-8 h-8 flex items-center justify-center p-1"
                  onClick={() => setIsEditable(true)}
                >
                  <Pencil className="w-5 h-5 " />
                </div>
                <div
                  className="cursor-pointer hover:bg-gray-100 rounded-full w-8 h-8 flex items-center justify-center p-1"
                  onClick={() => setIsOpenDeleteConfirm(true)}
                >
                  <Trash2 className="w-5 h-5 " />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isEditable ? (
              <div className="flex items-center gap-x-3">
                <button
                  className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200"
                  onClick={() => setIsOpenAddPassword(true)}
                >
                  Confirm
                </button>
                <button
                  className="px-7 py-2.5 rounded-[12px] bg-lightpurple text-[#7D7A9A]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200"
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                className="px-5 py-2.5 rounded-lg bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 flex items-center justify-center gap-x-3 lg:w-auto w-full"
                onClick={() => setIsEditable(true)}
              >
                <SquarePlus className="w-5 h-5 " />
                Add New Plan
              </button>
            )}
          </>
        )}
      </div>
      <hr />
      <div className="py-5 lg:px-10 px-5 space-y-5">
        {selectedPlan !== "Select" && !isEditable ? (
          <>
            <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-y-5">
              <div className="flex items-center gap-x-5 font-semibold text-[22px]">
                <p>{profile?.current_plan?.short_text} Plan:</p>
                <p>
                  {profile?.current_plan?.currency}{" "}
                  {profile?.current_plan?.price}
                </p>
              </div>
              {/* <div className="flex items-center gap-x-3">
                <p>Auto Renewal</p>
                <Switch
                  checked={isCheckAutoRenew}
                  disabled={!isEditable}
                  onCheckedChange={() => setIsOpenTogglePassword(true)}
                />
              </div> */}
            </div>
            <div className="flex items-center gap-x-5 flex-wrap gap-y-2">
              <p className="font-medium text-[#888] w-[150px]">Start Date</p>
              <input
                type="text"
                className="px-5 py-2.5 rounded-lg bg-white w-full lg:w-[200px] text-[#525252]"
                placeholder="Start Date"
                value={
                  profile?.subscription_start_date &&
                  dayFormatter(
                    (profile?.subscription_start_date as number) * 1000
                  )
                }
                readOnly
              />
            </div>
            <div className="flex items-center gap-x-5 flex-wrap gap-y-2">
              <p className="font-medium text-[#888] w-[150px]">Expiry Date</p>
              <input
                type="text"
                className="px-5 py-2.5 rounded-lg bg-[#f7f7f7] w-full lg:w-[200px] text-[#525252]"
                placeholder="End Date"
                value={
                  profile?.subscription_expiration_date &&
                  dayFormatter(
                    (profile?.subscription_expiration_date as number) * 1000
                  )
                }
                readOnly
              />
            </div>
          </>
        ) : (
          <p className="font-semibold text-[22px] text-[#8c8c8c]">
            No Plan Selected
          </p>
        )}
        {!profile?.current_plan && (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-5">
              <p className="font-medium text-lg">Enable Free Access Until</p>
              <div
                className="w-[190px] relative"
                onClick={() => setIsOpenCalendarDialog(true)}
              >
                <input
                  type="text"
                  value={date ? format(date as Date, "dd/MM/yyyy") : ""}
                  className="px-5 py-2.5 rounded-lg bg-white text-[#525252]"
                  placeholder="Select date"
                  readOnly
                />
                <CalendarDays className="w-5 h-5 absolute right-0 top-1/2 -translate-y-1/2 text-[#888] cursor-pointer" />
              </div>
            </div>
            <Switch
              checked={isFreeAccess}
              onCheckedChange={() => {
                setIsFreeAccess((prev) => !prev);
              }}
            />
          </div>
        )}
      </div>
      <div className="bg-[#f6f6ff] rounded-lg px-5 lg:px-10 py-3 hidden">
        <p className="font-medium text-lg text-[#615E83]">Invoice History</p>
      </div>
      <div className="py-5 px-5 lg:px-10 divide-y hidden">
        {new Array(3).fill("").map((_, i) => (
          <div className="flex py-2 gap-x-5 lg:gap-x-20 text-[#252323] flex-wrap gap-y-2">
            <div className="flex gap-x-1 lg:w-[300px]">
              <p>{i + 1}.</p>
              <div>
                <p>Monthly Subscription</p>
                <div className="flex items-center gap-x-2 text-xs text-[#888]">
                  <p>May 05, 2024</p>
                  <div className="w-1.5 h-1.5 rounded-full bg-[#888] flex-shrink-0"></div>
                  <p>06:05 pm</p>
                </div>
              </div>
            </div>
            <p>AED 299/month</p>
          </div>
        ))}
      </div>
      <CalendarDialog
        isOpenDialog={isOpenCalendarDialog}
        setIsOpenDialog={setIsOpenCalendarDialog}
        date={date}
        setDate={setDate}
      />
      <PasswordModal
        isOpenDialog={isOpenAddPassword}
        setIsOpenDialog={setIsOpenAddPassword}
        confirmAction={handleAddPlan}
        isLoading={addPlanLoading}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
      <PasswordModal
        isOpenDialog={isOpenUpdatePassword}
        setIsOpenDialog={setIsOpenUpdatePassword}
        confirmAction={handlePlanUpdate}
        isLoading={updatePlanLoading}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
      <PasswordModal
        isOpenDialog={isOpenTogglePassword}
        setIsOpenDialog={setIsOpenTogglePassword}
        confirmAction={handlePlanAutoRenewal}
        isLoading={updatePlanLoading}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
      <ConfirmDialog
        isOpenDialog={isOpenDeleteConfirm}
        setIsOpenDialog={setIsOpenDeleteConfirm}
        confirmAction={() => {
          setIsOpenDeletePassword(true);
          setIsOpenDeleteConfirm(false);
        }}
        title="Are you sure want to delete the user plan?"
      />
      <PasswordModal
        isOpenDialog={isOpenDeletePassword}
        setIsOpenDialog={setIsOpenDeletePassword}
        confirmAction={handleDeletePlan}
        isLoading={deletePlanLoading}
        value={password}
        setValue={setPassword}
        passwordError={passwordError}
        setPasswordError={setPasswordError}
      />
    </>
  );
};

export default Subscription;
