import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  deleteUserPlanApi,
  getRecentSunscriptionApi,
  getUserCountApi,
  getUserListApi,
  postAddUserPlanApi,
  postLoginAsUserApi,
  postUserCreateApi,
  postUserSuspendApi,
  putUserPlanUpdateApi,
  putUserSubscriptionUpdateApi,
  putUserUpdateApi,
} from "../api/user";
import {
  GetRecentSubscriptionReturn,
  GetUserCountReturn,
  GetUserListReturn,
  PostLoginAsUserProps,
  PostLoginAsUserReturn,
  PostUserCreateProps,
  PostUserSuspendProps,
  PostUserUpdateProps,
  PutUserPlanUpdateProps,
  PutUserSubscriptionUpdateProps,
} from "../types/types";

export const useGetUserList = ({
  email,
  index,
}: {
  email?: string;
  index?: number;
}) => {
  return useQuery<{
    results: Array<GetUserListReturn>;
    total_results: number;
  }>({
    queryKey: ["getUserList", email, index],
    queryFn: () =>
      getUserListApi({
        index: index as number,
        email: email,
      }),
  });
};

export const useGetUserListInfinite = ({ email }: { email?: string }) => {
  return useInfiniteQuery<{
    results: Array<GetUserListReturn>;
    total_results: number;
  }>({
    queryKey: ["getUserList", email],
    queryFn: ({ pageParam = 0 }) =>
      getUserListApi({
        index: pageParam as number,
        email: email,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (
        allPages?.flatMap((page) => page?.results)?.length <
        lastPage?.total_results
      ) {
        return allPages?.flatMap((page) => page?.results)?.length;
      }
    },
    getPreviousPageParam: (firstPage, allPages) => {
      if (
        allPages?.flatMap((page) => page?.results)?.length >
        firstPage?.total_results
      ) {
        return allPages?.flatMap((page) => page?.results)?.length;
      }
    },
  });
};

export const useGetUserCount = () => {
  return useQuery<GetUserCountReturn>({
    queryKey: ["getUserCount"],
    queryFn: () => getUserCountApi(),
  });
};

export const useGetRecentSubscription = () => {
  return useQuery<GetRecentSubscriptionReturn>({
    queryKey: ["getRecentSubscription"],
    queryFn: () => getRecentSunscriptionApi(),
  });
};

export const usePostUserCreate = () => {
  return useMutation({
    mutationKey: ["postUserCreate"],
    mutationFn: (data: PostUserCreateProps) => postUserCreateApi(data),
  });
};

export const usePostUserSuspend = () => {
  return useMutation({
    mutationKey: ["postUserSuspend"],
    mutationFn: (data: PostUserSuspendProps) => postUserSuspendApi(data),
  });
};

export const usePutUserUpdate = () => {
  return useMutation({
    mutationKey: ["putUserUpdate"],
    mutationFn: (data: Partial<PostUserUpdateProps>) => putUserUpdateApi(data),
  });
};

export const usePutUserSubscriptionUpdate = () => {
  return useMutation({
    mutationKey: ["putUserSubscriptionUpdate"],
    mutationFn: (data: PutUserSubscriptionUpdateProps) =>
      putUserSubscriptionUpdateApi(data),
  });
};

export const useAddUserPlan = () => {
  return useMutation({
    mutationKey: ["postAddUserPlan"],
    mutationFn: (data: Partial<PutUserPlanUpdateProps>) =>
      postAddUserPlanApi(data),
  });
};

export const usePutUserPlanUpdate = () => {
  return useMutation({
    mutationKey: ["putUserPlanUpdate"],
    mutationFn: (data: Partial<PutUserPlanUpdateProps>) =>
      putUserPlanUpdateApi(data),
  });
};

export const useDeleteUserPlan = () => {
  return useMutation({
    mutationKey: ["deleteUserPlan"],
    mutationFn: (data: Partial<PutUserPlanUpdateProps>) =>
      deleteUserPlanApi(data),
  });
};

export const usePostLoginAsUser = () => {
  return useMutation({
    mutationKey: ["postLoginAsUser"],
    mutationFn: (data: PostLoginAsUserProps) => postLoginAsUserApi(data),
  });
};
