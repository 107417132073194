import { CircleAlert, Eye, EyeOff } from "lucide-react";
import ButtonLoading from "../common/ButtonLoading";
import { Dialog, DialogContent } from "../ui/dialog";
import { useState } from "react";

type Props = {
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  confirmAction: () => void;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  passwordError: string;
  setPasswordError: React.Dispatch<React.SetStateAction<string>>;
};

const PasswordModal = ({
  isOpenDialog,
  setIsOpenDialog,
  confirmAction,
  value,
  setValue,
  isLoading,
  passwordError,
  setPasswordError,
}: Props) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <Dialog open={isOpenDialog} onOpenChange={setIsOpenDialog}>
      <DialogContent
        className="bg-white !rounded-2xl !lg:max-w-fit max-w-xs p-5 lg:p-10 font-poppins"
        data-testid="add-prompt-dialog"
      >
        <p className="font-semibold text-[22px]">Confirm Password</p>
        <div className="w-full">
          <div className="relative">
            <input
              type={isShow ? "text" : "password"}
              className={`w-full rounded-lg bg-[#7d7a9a]/5 px-5 py-2 border  ${
                passwordError ? "border-red-500" : "border-[#7d7a9a]/35"
              }`}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter password"
            />
            <div className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer">
              {isShow ? (
                <EyeOff className="size-4" onClick={() => setIsShow(false)} />
              ) : (
                <Eye className="size-4" onClick={() => setIsShow(true)} />
              )}
            </div>
          </div>
          {passwordError && (
            <p className="flex items-center gap-x-1 text-red-500 text-xs mt-0.5">
              <CircleAlert className="w-4 h-4" />
              {passwordError}
            </p>
          )}
        </div>

        <div className="flex flex-wrap justify-center items-center gap-3 lg:gap-x-10">
          <button
            className="px-7 py-2.5 rounded-[12px] bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all duration-200 w-full"
            onClick={confirmAction}
          >
            {isLoading ? <ButtonLoading /> : "Confirm password"}
          </button>
          <button
            className="px-7 py-2.5 rounded-[12px] bg-lightpurple text-[#7D7A9A]  border hover:bg-white hover:text-primary hover:border-primary transition-all duration-200 w-full"
            onClick={() => {
              setIsOpenDialog(false);
              setPasswordError("");
            }}
          >
            Cancel
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordModal;
